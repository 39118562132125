import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { DocumentsComponent } from '@customers/create-customer-account/individual/pages/documents/documents.component';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputFileComponent } from '@shared/ui/input-file/input-file.component';

@Component({
  selector: 'app-kyc-doc-form',
  standalone: true,
  imports: [CommonModule, InputFileComponent, RouterLink, ButtonDirective],
  templateUrl: './kyc-doc-form.component.html',
  styleUrl: './kyc-doc-form.component.scss',
})
export class KycDocFormComponent extends DocumentsComponent {
  constructor(elRef: ElementRef, renderer: Renderer2, cdr: ChangeDetectorRef) {
    super(elRef, renderer, cdr);
  }
}
