import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { IJointKyc } from '@core/models/admin/account/joint';
import { JointRegistrationService } from '@core/services/joint-registration.service';
import { LoadingService } from '@core/services/loading.service';
import { KycDocFormComponent } from '@customers/create-customer-account/shared-forms/kyc-doc-form/kyc-doc-form.component';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';

@Component({
  selector: 'app-joint-account-kyc-doc',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    InputLabelComponent,
    ReactiveFormsModule,
    KycDocFormComponent,
    BaseSelectDirective,
    RouterLink,
  ],
  templateUrl: './joint-account-kyc-doc.component.html',
  styleUrl: './joint-account-kyc-doc.component.scss',
})
export class JointAccountKycDocComponent {}
